<template>
<v-container>
  <v-card>
    <v-card-text class="d-flex flex-column justify-center text-center">
      <div class="d-flex justify-center">
        <v-img
          :src="require('@/assets/energy/WhiteMouseEnergy.png')"
          max-width="300px"
          contain
        ></v-img>
      </div>
      <div class="d-flex justify-center">
        <SiteLogo />
      </div>
      <div class="mb-3 mt-3">
        <h2 class="mb-3">{{status}}</h2>
        <h1>{{progression}}%</h1>
      </div>
      <v-progress-linear
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      SiteLogo: () =>
        import(
          /* webpackChunkName: "component-site-logo" */ "@/components/SiteLogo.vue"
        ),
    },
    computed: mapState({
      //
    }),
    props:[
      //
    ],
    data: () => ({
      status: "Fixing...",
      progression: 0,
      timing:0,
    }),
    created() {
      const urlParams = new URLSearchParams(window.location.search);
      this.progression = urlParams.get('progression')*1 ?? 0;
      this.timing = this.$_.clone(this.progression);
      //
      var that = this
      var interval = setInterval(()=>{
        that.timing += 1
        if(that.progression < 100) that.progression += 1
        if(that.timing == 20) that.resetSkin()
        if(that.timing == 40) that.resetApparel()
        if(that.timing == 60) that.refreshPage()
        if(that.timing >= 60 && that.timing < 100) this.status = "Refreshed page..."
        if(that.timing == 100) this.status = "Completed."
        if(that.timing == 120) this.status = "Redirecting..."
        if(that.timing >= 130) {
          clearInterval(interval);
          that.$router.push({name:"PageExploreLanding"});
        }
      },60);
    },
    mounted() {
      //
    },
    methods: {
      resetSkin() {
        this.status = "Reset skins...";
        this.$store.dispatch("resetSkin");
      },
      resetApparel() {
        this.status = "Reset apparels...";
        this.$store.dispatch("resetApparel");
      },
      refreshPage() {
        // window.location.reload(true);
        const newUrl = `${window.location.origin}${window.location.pathname}?progression=61`;
        window.location.href = newUrl;
      },
    }
  }
</script>